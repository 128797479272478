/* eslint-disable complexity */
import { Col, Form, InputNumber, Row } from 'antd';
import { OtherItem } from './OtherItem';
// import request from './request';
import { getColdWaterItemLabelByConfig } from './getColdWaterItemLabelByConfig';
import useGetCurrentFeeConfig from './useGetCurrentFeeConfig';
import { feeTips } from 'blog/type-file';
/** 月结单用的字段 */
export function MonthlyStatementItem() {
	const form = Form.useFormInstance();

	const { valuation, isLoading } = useGetCurrentFeeConfig({
		roomNumber: form.getFieldValue('roomNumber'),
	});

	return (
		<>
			<Row>
				<Col span={12}>
					<Form.Item
						label="电表上月读数"
						name="electricMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入电表上月读数'} */
							]
						}
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="电表本月读数"
						name="electricMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入电表本月读数'} */
							]
						}
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label="热水水表上月读数"
						name="hotWaterMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入热水水表上月读数'} */
							]
						}
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="热水水表本月读数"
						name="hotWaterMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入热水水表本月读数'} */
							]
						}
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表上月读数`}
						name="coldWaterMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入冷水水表上月读数'} */
							]
						}
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表本月读数`}
						name="coldWaterMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入冷水水表本月读数'} */
							]
						}
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<OtherItem />
		</>
	);
}
