/* eslint-disable complexity */
import { Col, Form, InputNumber, Row } from 'antd';
import { OtherItem } from './OtherItem';
import { getColdWaterItemLabelByConfig } from './getColdWaterItemLabelByConfig';
import useGetCurrentFeeConfig from './useGetCurrentFeeConfig';
import { feeTips } from 'blog/type-file';
// import request from './request';

export function LeaseItem() {
	const form = Form.useFormInstance();
	const { valuation } = useGetCurrentFeeConfig({
		roomNumber: form.getFieldValue('roomNumber'),
	});

	return (
		<>
			<Row>
				<Col span={12}>
					<Form.Item
						label="电表上月读数"
						name="electricMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入电表上月读数'} */
							]
						}
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="电表本月读数"
						name="electricMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入电表本月读数'} */
							]
						}
						hidden={valuation.electricityFee.disabled}
						tooltip={feeTips(valuation.electricityFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label="热水水表上月读数"
						name="hotWaterMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入热水水表上月读数'} */
							]
						}
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label="热水水表本月读数"
						name="hotWaterMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入热水水表本月读数'} */
							]
						}
						hidden={valuation.hotWaterFee.disabled}
						tooltip={feeTips(valuation.hotWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Row>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表上月读数`}
						name="coldWaterMeterReadingLastMonth"
						rules={
							[
								/* { required: true, message: '请输入冷水水表上月读数'} */
							]
						}
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						label={`${getColdWaterItemLabelByConfig(valuation)}水表本月读数`}
						name="coldWaterMeterReadingThisMonth"
						rules={
							[
								/* { required: true, message: '请输入冷水水表本月读数'} */
							]
						}
						hidden={valuation.coldWaterFee.disabled}
						tooltip={feeTips(valuation.coldWaterFee)}
					>
						<InputNumber step="0.1" />
					</Form.Item>
				</Col>
			</Row>

			<Form.Item
				label="退回押金"
				name="deposit"
				rules={[
					{ required: true, message: '请输入押金' },
					{
						// https://ant.design/components/form-cn#rule
						pattern: /\d[05]{2,3}/,
						message: '押金一般是整数或X50结尾',
						warningOnly: true,
					},
				]}
			>
				<InputNumber />
			</Form.Item>

			<OtherItem />
		</>
	);
}
